import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContatoPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section className="intro">
      <div className="intro-container">
        <h2>Contato</h2>
        <p>Para entrar em contato com a nossa equipe, por favor preencha o formulário abaixo.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <form
          name="contato"
          method="post"
          action="/contato-sucesso"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contato" />
          <div className="form-field">
            <label htmlFor="nome">Nome</label>
            <input type="text" name="nome" id="nome" />
          </div>
          <div className="form-field">
            <label htmlFor="sobrenome">Sobrenome</label>
            <input type="text" name="sobrenome" id="sobrenome" />
          </div>
          <div className="form-field">
            <label htmlFor="email">E-mail</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="form-field">
            <label htmlFor="mensagem">Mensagem</label>
            <textarea name="mensagem" id="mensagem" rows="6" />
          </div>
          <ul className="form-actions">
            <li>
              <input type="submit" value="Enviar mensagem" className="form-actions-submit" />
            </li>
            <li>
              <input type="reset" value="Borrar" />
            </li>
          </ul>
        </form>
        <Link to="/" className="call-to-action">Voltar à página inicial</Link>
      </div>
    </section>
  </Layout>
)

export default ContatoPage
